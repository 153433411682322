/* NewDriver.css */

.custom-container {
  max-width:900px;
    margin: 0 auto;
    padding: 20px;
    height: fit-content;

  }
  .new-driver-bg{
    background-image: url('./images/gridbg1.jpg');
    /* background:url('./images/Blue\ Minimalist\ Linktree\ Background\ \(1\).png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  /* Hide label numbering for mobile view */
  @media (max-width: 768px) {
    .custom-container label::before {
      display: none;
    }
  }
  
  form .shadow-lg input{
    width: 250px;
  }
  .shadow-lg .row{
    margin-left: 40px;
  }
  

  .txt{
    font-family:'Segoe UI';
    animation:  txt  7s ease-out  normal;
  }
  @keyframes txt{
    0%{transform:scale(0.9) ;}
    25%{transform:scale(1.1) ;}
    60%{transform:scale(0.9) ;}
    100%{transform:scale(0.9) ;}
  }