.grid-container-1{
    display: grid;
    grid-template-columns: auto auto auto ;
    gap: 10px;
    padding: 10px;
    margin-left: 40px;
  }
  .hello-1{
      display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background-image: url('./images/gridbg1.jpg');
         /* background:url('./images/Blue\ Minimalist\ Linktree\ Background\ \(1\).png'); */
         background-position: center;
         background-repeat: no-repeat;
         background-size: cover;
  }
  .hii-1{
    background-color:#e2eff1cb;
    margin:15px 0px;
        padding: 10px;
        /* width: 100%; */
        border-radius: 8px;

        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        width: 950px;
       
      }
      /* .txt{
        font-family:'Segoe UI';
        animation:  txt  7s ease-out normal alternate;
        
      } */
      /* @keyframes txt{
        0%{transform:scale() ;}
        25%{transform:scale() ;}
        40%{transform:scale() ;}
        100%{transform: scale();}
      } */
      
      .grid-container-1 input {
        width:250px;
        padding: 7px;
        margin: 5px 0;
        border: 1px solid white;
        border-radius: 4px;
        box-sizing: border-box; 
  }
  select{
    width: 100%;
      padding: 7px;
      margin: 5px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
}
.flex button{
    background-color: rgb(49, 154, 121);
    padding: 7px;
    min-width: 200px;
    font-size: 20px;
    cursor: pointer;
    
}
.flex{
    display: flex;
    justify-content: space-evenly;
}
#register-submit-button{
  margin-left: 400px;
}
@media only screen and (max-width: 480px) {
 
  .grid-container-1{
    display: grid;
    grid-template-columns: auto ;
    justify-content: center; 
    margin-left: 0px;
  }
  #register-submit-button{
    margin-left: 160px;
  }

  }
  @media only screen and (max-width: 768px) {


  }