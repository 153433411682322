 
 #thead{
  background-color:#c6c5b3 ;
  border-width:0px 0px 0px 1px;
} 
.Agentinfo{
/* background-color:#e2eff1; */
background-color: transparent;
width:100%;
margin: 0px;
padding: 1%;

border-radius: 7px;
height: fit-content;



}
.Agentbg{

  /* background-image: url('./images/gridbg1.jpg'); */
  background-image: url('https://img.freepik.com/free-vector/white-wave-white-background-with-blue-lines_483537-4174.jpg?size=626&ext=jpg&ga=GA1.1.763110646.1701087430&semt=ais');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 5% 5% 5%;
}
.txt{
 font-family:'Segoe UI';
 animation:  txt  7s ease-out  normal;
}
@keyframes txt{
 0%{transform:scale(0.9) ;}
 25%{transform:scale(1.1) ;}
 60%{transform:scale(0.9) ;}
 100%{transform:scale(0.9) ;}
}
.agent-id-feild{

margin-left:'10px';
height:'42px'
}

#infobutton{
height: 40px;

}
#loading-ani-agent-info{
margin-left: 500px;
}

@media only screen and (max-width:480px) {
.Agentbg{
  height: fit-content;
  
}
#loading-ani-agent-info{
margin-left: 200px;
}
#Scroll-bar{
overflow: hidden;
overflow-x: scroll;
}
.Agentbg{
height: fit-content;
background-image: url('./images/gridbg1.jpg');
background-position: center;
background-repeat: no-repeat;
background-size: cover;
padding: 0%;
}

}