body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.center {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.center-container {
display: flex;
padding: 15px;
justify-content: center;
}


/* .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
} */


.numbered-button {
  display: flex;
  align-items: center;

  
}

 

.number {
  font-size: 20px;
  margin-right: 5px;
  color: rgb(245, 236, 236);
}


.zindex{
  z-index: -1;
  margin-bottom: 200px;
}

.Owner video{
position: absolute;
  margin: 0px;
  padding:0px;
  top: 0px;
  overflow: hidden;
  bottom: 0px;
  /* min-width: auto; */
  height: 120vh;
  object-fit: cover;
  /* background-position: center; */
  /* background-blend-mode: luminosity;/\ */
/* filter: blur(5px); */

  z-index: -2; 
}

/* Media Query for Mobile */
@media screen and (max-width: 767px) {
.Owner video {
    display: block; /* Hide video on small screens */
}
}




/* today */

.grid-container {
display: grid;
grid-template-columns: 1fr 2fr; /* Two equal columns */
grid-gap: 16px; /* Adjust the gap between columns if needed */
}

.grid-item {
width: 100%;
}

.internal-grid-container {
display: grid;
grid-template-columns: 1fr 1fr ; /* Two equal columns for the internal grid */
gap: 16px; /* Adjust the gap between columns if needed */
}

.internal-grid-item {
width: 100%;
}

/* Add additional styling for your content as needed */
.navbar-expand-md{
background-color: #a9d7f63d;
border-radius: 10px;

}
form #disfield1{
max-width: 300px;
color: rebeccapurple;
}
.ownerbg{
background-color: #e2eff14c;
height: 100vh;
}
.requestpostbg{
background-color: #8cdadb5e;
padding: 0px 0px;
border-radius: 10px;

}
.gridhead{
background-color: #f7c369fc;
/* display:inline; */
color: white;
padding: 5px 10px;
border-radius: 10px;
text-align: center;

}
.mytrucks{
background-color: #f7c369fc;
/* display: inline; */
color: white;
padding: 2px 5px;
border-radius: 10px;
text-align: center;
}
.navhome{
font-size: 18px;
color: #181313;
border: none;
border-radius: 30px;
font-weight: bold;
background-color: transparent;
cursor: pointer;
padding: 7px 20px;
}
.navhome:hover{
background-color: #f7c369fc; 
}
#gridpart:hover{

box-shadow: 5px 10px 10px   rgba(128, 128, 128, 0.381);
}
.dropdown-content{
background-color: rgb(193, 211, 239);
border-radius: 7px;

}
.owner-body{
margin-left: 2%;
}
#agent-button{
margin-left: 340px;

font-weight: bold;
} 
#driverbutton{
margin-left: 38px;
}

@media only screen and (max-width: 480px) {
.calsubmit{
    margin-left: 0px;/* No margin for mobile screens */
}


}


.dropmenu{
background-color: #f7c469;
}

.dropdowm-menu{
background-color: #f7c36997;
}
.ownerbg{
background-color: #1813131b;
padding-bottom: 20px;
}

#owneritems{
background-color: transparent;
border-radius: 22px;

padding: 7px ;
}
#owneritems:hover{
background-color: orange;
border-radius: 22px;
padding: 7px ;
}
.my-trucks-form{
/* margin-left: 50px; */
margin-top: 47px;

}
#calculator-heading{
margin-top: 48px;
margin-right: 24px;
border-radius: 5px;
text-align: center;
margin-bottom: 6px;
}
.thead{
background-color: #00aaffc1;
}
.success-message{
z-index: 999;
position:  absolute;
left: 40%;
}


#loading-ani-in-avilable-trucks{
margin-left: 340px;
}
/* #onwer-interface-distancecal-spaceing{
display: flex;
grid-template-columns: 2;
grid: 2px;
} */
@media only screen and (max-width: 768px) {
  .calsubmit{
      margin-left: 0px;/* No margin for mobile screens */
  }
 #owneritems:hover{
background-color: orange;
border-radius: 22px;
padding: 7px ;
width: fit-content;
}
#loading-ani-in-avilable-trucks{
  margin-left: 200px;
}
#owner-grid-2{
  order: 1;
  
}
#calculator-heading{
  margin-top: 48px;
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 26px;
 }
#owner-grid-1{
order: 2;

}
.my-trucks-form{
  margin-left: 0px;
  margin-top: 50px;
 }
 .ownerbg{
  background-color: #e2eff14c;
  height: 150vh;
}
.success-message{
  z-index: 999;
  position:  absolute;
  left: 24%;
 
}

 
}
#onwernavbarbg{
background-color: #edf2fa85;
padding: 18px;

}
.lfeild{
background-color: red;
}