.faq-bg {
    /* background-image: url('./images/q-bg3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    padding: 50px 100px 100px 100px;
}

@media only screen and (max-width:768px) {
    .faq-bg {
        background-image: url('./images/q-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 0px;
    }
}
#inner-bg-faq{
    background-color:rgba(242, 237, 212, 0.742);
     
}
.back-icon{
    margin: 20px 0px 3px 50px;
    background-color:rgba(0, 0, 255, 0.582) ;
    width: fit-content;
    padding:15px;
    border-radius: 50%; 
}
.back-icon:hover{
    background-color: green;
    width: fit-content;
    padding:15px;
    border-radius: 50%; 
}
 
.background-faq{
    background-image: url('./images/f-bg3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}
 