.grid-container-2{
    display: grid;
    grid-template-columns: auto auto auto ;
    gap: 10px;
    padding: 10px;
    margin-left: 30px;
    
  
  }
  .grid-container-2 label{
    display: flex;
    flex: start;
  }
  .new-truck-bg{
    background-image: url('./images/gridbg1.jpg');
    /* background:url('./images/Blue\ Minimalist\ Linktree\ Background\ \(1\).png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1px;
  }
  .hello-2{
        display: flex;
        justify-content: center;
        min-height: 100vh;
        
  }
  .hii-2{
    background-color:#e3f1f3;
        padding: 10px;
        border-radius: 8px;
        margin: 20px 0px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        width: 1000px;
       
      }
      
    .grid-container-2  input {
        width: 90%;
        /* padding: 7px; */
        /* margin: 5px 0; */
        /* border: 1px solid #ccc; */
        border-radius: 7px;
        box-sizing: border-box;
      
  }
  select{
      width: 100%;
      padding: 7px;
      margin: 5px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
}
.flex1 button{
    padding: 7px;
    min-width: 50px;
    font-size: 20px;
    cursor: pointer;
}

.flex1{
    display: flex;
    justify-content: center;
}

.hii-2 .txt{
  font-family:'Segoe UI';
  animation:  txt  7s ease-out  normal;
}
@keyframes txt{
  0%{transform:scale(0.9) ;}
  25%{transform:scale(1.1) ;}
  60%{transform:scale(0.9) ;}
  100%{transform:scale(0.9) ;}
}

@media only screen and (max-width:480px) {

  .grid-container-2{
    display: grid;
    grid-template-columns: auto  ;
    gap: 10px;
    padding: 10px;
    margin-left: 30px;
  
  }
}
.error-input {
  border: 1px solid red;
}
.grid-container-2 label {
  display: flex;
  align-items: center; /* Align items vertically */
}

.grid-container-2 label sup {
  color: red;
  font-size: 15px;
  margin-left: 5px; /* Adjust the margin to control distance from the label text */
}