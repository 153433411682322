
.login-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
  }
  
  .login-box {
    display: flex;
    max-width: 800px;
    width: 100%;
    height: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: #fff;
  }
  
  .login-image {
    flex: 1;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }
  
  .login-image img {
    width: 100%;
    height: 100%;
   
  }
  
  .vertical-line {
    width: 2px;
    background-color: #ccc;
    align-self: center;
  }
  
  .login-form {
    flex: 1;
    padding: 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-button {
    width: 50%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .error-message {
    margin-bottom: 15px;
    color: #e74c3c;
    font-weight: bold;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  /* Dashboard.css */
.dashboard-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
  }
  
  .button-container {
    display: flex;
   
    
    gap: 20px;
  }
  
  .dashboard-button {
    padding: 15px 30px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dashboard-button:hover {
    background-color: #0056b3;
  }
  .dashboard-container {
    
    height: 60vh; /* Full height of the viewport */
    background: linear-gradient(to right, #c1acd6, #7a9ad0); /* Linear gradient background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow */
    border-radius: 10px; /* Optional: for rounded corners */
    padding: 20px; /* Optional: for some inner spacing */
    margin: 100px;

  }
  
  .button-container {
    display: flex;
    align-items: center;
    background-color: white; /* Background color for the button container */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .dashboard-button:hover {
    background-color: #45a049;
  }
  
  .vertical-line1 {
    width: 1px;
    height: 70px;
    background-color: #ccc;
    margin: 0 10px;
  }
  .vertical-line {
    width: 1px;
    height: 200px;
    background-color: #ccc;
    margin: 0 10px;
  }
  .mobile-warning-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Light black background */
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .mobile-warning-message {
    font-size: 20px;
    border: 2px solid white;
    padding: 20px;
    border-radius: 10px;
  }
  