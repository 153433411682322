 
.My-profile-bg {
    display: flex;
    background-image:radial-gradient( rgba(135, 108, 234, 0.626),white);
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .card img {
    width: 140px;
    height: 140px;
    display: block;
    margin: auto;
  }
  #card-image-area{
    text-align: center;
    width: 140px;
    display: block;
    margin: auto;
    height: fit-content;
  }

  #card-bg{
    background-color:rgba(208, 199, 145, 0.39);
    width: 450px;
  }
  
  
