 .navbar-list {
    list-style-type: none;
    margin: 0;
    
    
  }
  .mypro{
    margin-left: 950px;
  }

  .dropdown-content  {
    display: none;
    position: absolute;
    background-color: white;
    right: 1;
    z-index: 999;
  }
  
  .navbar-it:hover .dropdown-content {
    margin-top:10;
    display: block;
  }
  .navbar-ite:hover .dropdown-content {
    display: block;
  }
  .homeinnav{
    background-color: transparent;
    font-weight: bold;
  
  }
 
  .navbar{
    background-color: rgba(126, 175, 179, 0.5);
    background-color: blur(20px);
    color: black;
    border-radius: 1%;
    position: fixed;
padding-bottom: 10px;
 

  }
  .dropdown-menu-dark{
    width: 270px;
    height: 250px;
  }
 
  .dropdown-toggle{
    font-weight: bold;
    color:'E0F4FF';
    background-color: transparent;
    z-index: 2;
  }
  #u{
    max-width: 200px;
  }
  .dis{
    margin-left: 30px;
  }
   

  
