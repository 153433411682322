.hii-3{
  background-color: #f9f7f7;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      width: 400px;
      background-color:#e2eff1;
    }
    
    #posting-width{
      max-width: 80%;
    }

    .hii-3 input {
      width: 100%;
      margin:  0;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box; 
  }

  #truck-send-otp{
    margin-left: 45%;
  }

  @media only screen and (max-width: 768px) {
  #truck-send-otp{
    margin-left: 30%;
  }
  #posting-width{
    max-width: 100%;
  }
}
 .truck-posting-bg{
  background-image: url('./images/gridbg1.jpg');
  /* background:url('./images/Blue\ Minimalist\ Linktree\ Background\ \(1\).png'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1px;
  height: fit-content;
  padding-bottom: 1%;
 }
 .flex-2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 40px;
  margin-top: 20px;
 }
 #truck-posting-form{
  margin-top: 50px;
  margin-bottom: 100px;
 }
 
 @media  only screen and (max-width:768px) {
  .truck-posting-bg{
    height:fit-content;
  }
  #truck-posting-form{
    margin-top: 0px;
   }
   .truck-posting-bg{
    background-image: url('./images/gridbg1.jpg');
    /* background:url('./images/Blue\ Minimalist\ Linktree\ Background\ \(1\).png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1px;
    height: fit-content;
    padding-bottom: 0%;
   }
 }



 
 .otp-input {
  border: 2px solid #ccc;
  padding: 10px;
  font-size: 16px;
  outline: none;
}
.tick-icon, .cross-icon {
  display: none;
  vertical-align: middle;
}
.valid  {
  display: inline;
  outline: none;
  border-color: green;

}
.invalid {
  outline: none; /* Remove default focus outline */
border-color:red; 
display: inline;
}