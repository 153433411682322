.options {
  background-color: rgb(240, 234, 234);
  padding: 20px;
  display: flex;
  flex-direction: column;
 
}

.option-button {
  /* background-color: rgb(191, 124, 225) ; */
   color: rgb(241, 237, 237); 
  border: none;
  /* padding: 8px 15px; */
  font-size: 16px;
  width: 220px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  position: relative;
  margin-left: 30px;
}
.txt{
  font-family:'Segoe UI';
  animation:  txt  7s ease-out  normal;
}
@keyframes txt{
  0%{transform:scale(0.9) ;}
  25%{transform:scale(1.1) ;}
  60%{transform:scale(0.9) ;}
  100%{transform:scale(0.9) ;}
}

.option-button .ownzoom:hover {
  /* background-image: linear-gradient(to left ,#4d424d,#b1e9a3); */
  font-weight: bold;
  /* transform: scale(1.1); */
  /* border: none; */
}

.active {
  background-color: #007bff;
  color: white;
}

.content {
 
  background-color: #fff;
  border-left: 1px solid #ccc;
  overflow: auto;
  max-width: 200px;
  min-height: 100%;
}
 

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn .button-number-1 {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(21, 20, 20);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  margin-right: 40px;
  padding-right: 60px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
}

.accordian-header {

  word-spacing: 10px;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table thead th {
  background-color: #f8f9fa;
  color: #333;
  font-weight: bold;
}

.table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.table tbody tr:hover {
  background-color: #e0e0e0;
}

.table td {
  padding: 0.5rem;
}
  
button .btn-danger {
  max-width: 10%;
  padding: 10px;
}

.smaller-input {
  width: 100px;
  padding: 5px;
}

/* ________________________________________ */

#thead{
  background-color:#c6c5b3 ;
  border-width:0px 0px 0px 1px;
  font-weight: normal;
  text-align: center;
} 
#Search-by-registration{
  max-width: 30%;
}
#manage-forms{
  display: flex;
  align-items: center;
}

#manage-forms1{
  display: flex;

}
#manage-forms3{
    display: flex;

}
 
#Search-by-registration{
  max-width: 200px;
}
 
#input{
  margin-left: 40px;
}
#get-info{
margin-left: 30px;
}

#tbrNumber{
  max-width: 1px;
}

@media only screen and (max-width: 480px) {

#Search-by-registration{
  width: 300px;
}  
#manage-booking-form{
  max-width: 400px;
}
.page-link {
  /* ... default link styles */
}

/* Highlight the active page link */
.page-item.active .page-link {
  background-color: #e5f5ff; /* Example background color */
  color: #004a95; /* Example text color */
}
#content {
  background-color: #fff;
  border-left: 1px solid #ccc;
  overflow: auto;
  max-width:100vh;
  margin-top: 10px;
}
#Onwer-items{

  display: none;
 }
 

#manage-forms1{
  display: block;

}
#input{
  margin-left: 23px;
}
#manage-forms3{
  display: block;

}
#s-b-r-n-h2{
  font-size: 26px;
  text-align: center;
  
}
#get-info{
  display: flex;
  justify-content: center;
  margin-right: 40px;
  }
 

}